body {
  background-color: var(--neutralDark);
  color: var(--textPrimary);
  font-family: 'Segoe UI', 'Open Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

a {
  text-decoration: none;
}
